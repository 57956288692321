import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import {
  HMSAudioMode,
  HMSRecordingState,
  selectIsConnectedToRoom,
  selectIsSomeoneScreenSharing,
  selectLocalPeer,
  selectPeerScreenSharing,
  selectPeers,
  selectRecordingState,
  selectScreenShareByPeerID,
  selectUnreadHMSMessagesCount,
  useHMSActions,
  useHMSStore
} from '@100mslive/react-sdk';
import cs from 'classnames';
import { addDoc, collection, getFirestore } from 'firebase/firestore';

import styles from './Session.module.scss';

import LocalParticipant from '../../components/LocalParticipant/LocalParticipant';
import LocalScreenParticipant from '../../components/LocalScreenParticipant/LocalScreenParticipant';
import Participant from '../../components/Participant/Participant';
import { ParticipantChatWrapper } from '../../components/ParticipantChatWrapper/ParticipantChatWrapper';
import { ParticipantListWrapper } from '../../components/ParticipantListWrapper/ParticipantListWrapper';
import ScreenParticipant from '../../components/ScreenParticipant/ScreenParticipant';
import { SessionControlsWrapper } from '../../components/SessionControlsWrapper/SessionControlsWrapper';
import {
  CONFIRM_END_SESSION_MESSAGE,
  CONFIRM_END_SESSION_TITLE,
  CONFIRM_LEAVE_SESSION_MESSAGE,
  CONFIRM_LEAVE_SESSION_TITLE
} from '../../config/ConfirmModal';
import { APP_TITLE } from '../../constants/app';
import { ButtonState } from '../../constants/buttons';
import { NODE_ENVS } from '../../constants/env';
import { MAX_PARTICIPANTS } from '../../constants/grid';
import { Roles } from '../../constants/roles';
import { TIME_CODE } from '../../constants/timeCode';
import { hmsStore } from '../../hms';
import useCreateTimeCode from '../../hooks/useCreateTimeCode';
import useHostPresent from '../../hooks/useHostPresent';
import useHostRecording from '../../hooks/useHostRecording';
import useNotifications from '../../hooks/useNotifications';
import useTimer from '../../hooks/useTimer';
import useUploadProgress from '../../hooks/useUploadProgress';
import { useAppState } from '../../providers/AppState';
import { useRecordingContext } from '../../providers/RecordingProvider';
import { EndSessionButton } from '../../stories/Buttons/EndSessionButton/EndSessionButton';
import { SecondaryButton } from '../../stories/Buttons/SecondaryButton/SecondaryButton';
import { CountDownTimer } from '../../stories/CountDownTimer/CountDownTimer';
import { Footer } from '../../stories/Footer/Footer';
import { Header } from '../../stories/Header/Header';
import { PrimaryIconButton } from '../../stories/IconButtons/PrimaryIconButton/PrimaryIconButton';
import { Chat } from '../../stories/Icons/Chat/Chat';
import { Help } from '../../stories/Icons/Help/Help';
import { LoadingSpinnerIcon } from '../../stories/Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';
import { Users } from '../../stories/Icons/Users/Users';
import { Logo } from '../../stories/Logo/Logo';
import { ConfirmModal } from '../../stories/Modals/ConfirmModal/ConfirmModal';
import {
  EndTakeModal,
  UploadTakeData
} from '../../stories/Modals/EndTakeModal/EndTakeModal';
import { HelpModal } from '../../stories/Modals/HelpModal/HelpModal';
import { UploadingModal } from '../../stories/Modals/UploadingModal/UploadingModal';
import { ParticipantGrid } from '../../stories/ParticipantGrid/ParticipantGrid';
import { Take } from '../../stories/Take/Take';
import { Timer } from '../../stories/Timer/Timer';
import { Tooltip, TooltipPosition } from '../../stories/Tooltip/Tooltip';
import { UploadProgress } from '../../stories/UploadProgress/UploadProgress';
import { JoinStep } from '../Join/Join';

export const SessionPage = () => {
  const navigate = useNavigate();

  const isScreenSharing = hmsStore.getState(selectIsSomeoneScreenSharing);
  const presenter = hmsStore.getState(selectPeerScreenSharing);
  const screenShareVideoTrack = hmsStore.getState(
    selectScreenShareByPeerID(presenter?.id)
  );
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const peers = useHMSStore(selectPeers);
  const localPeer = hmsStore.getState(selectLocalPeer);
  const unreadMessages = useHMSStore(selectUnreadHMSMessagesCount);
  const recordingState = useHMSStore(selectRecordingState);

  const isHost = localPeer?.roleName === Roles.Host;

  const { initUploadProgress } = useUploadProgress();

  const { addTimeCode } = useCreateTimeCode();

  const { isGroup, eventName, eventId, groupId, joinData, sessionData } =
    useAppState();
  const {
    isRecording,
    isUploading,
    isComplete,
    take,
    progress,
    progressText,
    cameraBlobUrl,
    screenBlobUrls,
    recordingTime,
    uploadAssets,
    updateRecordingTime,
    toggleRecording,
    addVideoEnabled,
    removeVideoEnabled,
    updateTake
  } = useRecordingContext();
  const { minutes, seconds, totalSeconds, startTimer, resetTimer } = useTimer();
  const { updateHostPresent } = useHostPresent(
    JoinStep.Joined,
    eventId,
    groupId
  );
  const { hostRecording } = useHostRecording(isHost, eventId, groupId);

  const [showCountdown, setShowCountdown] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [testParticipants, setTestParticipants] = useState(0);

  useNotifications({
    isChatShowing: showChat,
    resetTimer
  });

  const [uploadingModalOpen, setUploadingModalOpen] = useState(false);
  const [finishTakeModalOpen, setFinishTakeModalOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [endSessionConfirmModalOpen, setEndSessionConfirmModalOpen] =
    useState(false);

  const openUploadingModal = () => setUploadingModalOpen(true);
  const openFinishTakeModal = () => setFinishTakeModalOpen(true);
  const openHelpModal = () => setHelpModalOpen(true);
  const openEndSessionConfirmModal = () => setEndSessionConfirmModalOpen(true);

  useEffect(() => {
    async function joinSession() {
      if (joinData && sessionData) {
        const config = {
          userName: joinData.name,
          authToken: sessionData.token,
          settings: {
            isAudioMuted: false,
            isVideoMuted: false
          },
          metaData: JSON.stringify({
            sessionId: sessionData.uploadId
          })
        };

        await hmsActions.join(config);

        // For higher quality sound
        await hmsActions.setAudioSettings({ audioMode: HMSAudioMode.MUSIC });
      }
    }

    window.addEventListener('beforeunload', () => void hmsActions.leave());
    window.addEventListener('onunload', () => void hmsActions.leave());

    joinSession();
  }, []);

  useEffect(() => {
    if (hostRecording) {
      toggleRecording();
      startTimer();
      setShowCountdown(true);
    } else if (!hostRecording && isRecording) {
      toggleRecording();
      resetTimer();
    }
  }, [hostRecording]);

  useEffect(() => {
    updateRecordingTime(totalSeconds);
  }, [totalSeconds]);

  useEffect(() => {
    async function init() {
      if (isConnected && localPeer) {
        await initUploadProgress(sessionData!.uploadId, localPeer.id);

        if (localPeer.roleName === Roles.Host && isGroup) {
          await updateHostPresent();
        }
      }
    }

    init();
  }, [isConnected, localPeer]);

  useEffect(() => {
    if (!isRecording && isUploading) openUploadingModal();
  }, [isRecording, isUploading]);

  const handleStartRecording = () => {
    setShowCountdown(false);
  };

  const handleGoToTakeClicked = () => {
    setUploadingModalOpen(false);

    if (isHost) {
      openFinishTakeModal();
    } else {
      updateTake();
    }
  };

  const handleLeaveSession = () => {
    if (isHost) {
      void hmsActions.endRoom(true, 'Host ended session');
    } else {
      void hmsActions.leave();
    }

    navigate(`/session-ended/${sessionData?.uploadId}`);
  };

  const handleSubmitTake = async (data: UploadTakeData) => {
    // TODO: move to Firebase services
    await addDoc(
      collection(getFirestore(), `uploads/${sessionData?.uploadId}/takes`),
      {
        usedScreenShare: data.usedScreenShare,
        hasAssets: data.hasAssets,
        take,
        comments: data.comments,
        deleted: false
      }
    );

    if (data.zip) await uploadAssets(data.zip);
  };

  const handleDeleteTake = async () => {
    // TODO: move to Firebase services
    await addDoc(
      collection(getFirestore(), `uploads/${sessionData?.uploadId}/takes`),
      {
        take,
        deleted: true
      }
    );
  };

  const handleFinishTake = () => {
    setFinishTakeModalOpen(false);
    updateTake();
  };

  useEffect(() => {
    async function process() {
      try {
        if (sessionData && localPeer?.id) {
          if (
            isRecording &&
            recordingState.browser.state !== HMSRecordingState.STARTED
          ) {
            await hmsActions.startRTMPOrRecording({ record: true });
            await addTimeCode(
              sessionData.uploadId,
              localPeer.id,
              TIME_CODE.HOST_START_TAKE,
              take
            );
          } else if (
            !isRecording &&
            recordingState.browser.state === HMSRecordingState.STARTED
          ) {
            await hmsActions.stopRTMPAndRecording();
            await addTimeCode(
              sessionData.uploadId,
              localPeer.id,
              TIME_CODE.HOST_STOP_TAKE,
              take
            );
          }
        }
      } catch (error) {
        console.error('Error processing recording', error);
      }
    }

    process();
  }, [isRecording, localPeer?.id]);

  const handleParticipantChatClicked = () => {
    setShowChat((c) => !c);
    setShowParticipants(false);
  };

  const handleParticipantListClicked = () => {
    setShowParticipants((c) => !c);
    setShowChat(false);
  };

  const handleCloseSideBarClicked = () => {
    setShowParticipants(false);
    setShowChat(false);
  };

  const wrapperClass = cs({
    [styles.sidePanelWrapper]: true,
    [styles.open]: showChat || showParticipants
  });

  const backgroundClass = cs({
    [styles.background]: true,
    [styles.open]: showChat || showParticipants
  });

  const panelClass = cs({
    [styles.panel]: true,
    [styles.open]: showChat || showParticipants
  });

  const chatWrapperClass = cs({
    [styles.chatWrapper]: true,
    [styles.open]: showChat
  });

  const participantWrapperClass = cs({
    [styles.participantWrapper]: true,
    [styles.open]: showParticipants
  });

  return (
    <section className={styles.container}>
      <Helmet>
        <title>
          {APP_TITLE} | {eventName} Session
        </title>
      </Helmet>
      <div className={styles.mainContainer}>
        {/* <Beforeunload /> */}
        <Header
          left={
            <section className={styles.titleWrapper}>
              <Logo color="white" scale={0.02} />
              <div className={styles.divider}></div>
              <h1 className={styles.eventName}>{eventName}</h1>
            </section>
          }
          center={
            <div className={styles.header}>
              <Timer minutes={minutes} seconds={seconds} />
              <Take take={take} />
            </div>
          }
          right={
            <div className={styles.rightContainer}>
              {isRecording && <UploadProgress progress={progress} />}
              {(peers.length > 1 ||
                isGroup ||
                import.meta.env.MODE === NODE_ENVS.DEVELOPMENT) && (
                <>
                  <Tooltip
                    text="Participants"
                    position={TooltipPosition.SOUTH}
                    disabled={false}
                  >
                    <PrimaryIconButton
                      icon={<Users />}
                      state={
                        showParticipants
                          ? ButtonState.Active
                          : ButtonState.Enabled
                      }
                      onClick={handleParticipantListClicked}
                    />
                  </Tooltip>

                  <Tooltip
                    text="Session chat"
                    position={TooltipPosition.SOUTH}
                    disabled={false}
                  >
                    <PrimaryIconButton
                      icon={<Chat />}
                      state={
                        showChat ? ButtonState.Active : ButtonState.Enabled
                      }
                      onClick={handleParticipantChatClicked}
                      notification={unreadMessages > 0}
                    />
                  </Tooltip>
                </>
              )}
              <Tooltip
                text="Help"
                position={TooltipPosition.SOUTH}
                disabled={false}
              >
                <PrimaryIconButton
                  icon={<Help color="white" />}
                  state={ButtonState.Enabled}
                  onClick={openHelpModal}
                />
              </Tooltip>
            </div>
          }
        />
        <section className={styles.videoContainer}>
          {isConnected ? (
            <ParticipantGrid
              isScreenSharing={isScreenSharing}
              numberOfParticipants={testParticipants + peers.length}
              participantVideos={[
                ...peers
                  .filter((it) => !it.isLocal)
                  .map((peer) => (
                    <Participant
                      key={`peer-${peer.id}`}
                      peer={peer}
                      isRecording={isRecording}
                      onVideoEnabled={addVideoEnabled}
                      onVideoDisabled={removeVideoEnabled}
                    />
                  )),
                ...(localPeer
                  ? (Array(testParticipants).fill(
                      <Participant
                        key={`peer-${localPeer?.id}`}
                        peer={localPeer}
                      />
                    ) as ReactNode[])
                  : []),
                localPeer && <LocalParticipant peer={localPeer} />
              ]}
              screenVideo={
                <>
                  {localPeer && presenter?.isLocal && screenShareVideoTrack && (
                    <Fragment key={`local-screen-${screenShareVideoTrack.id}`}>
                      <LocalScreenParticipant
                        peer={localPeer}
                        track={screenShareVideoTrack}
                        isRecording={isRecording}
                      />
                    </Fragment>
                  )}
                  {presenter && !presenter.isLocal && screenShareVideoTrack && (
                    <Fragment key={`screen-${screenShareVideoTrack.id}`}>
                      <ScreenParticipant
                        peer={presenter}
                        track={screenShareVideoTrack}
                        isRecording={isRecording}
                      />
                    </Fragment>
                  )}
                </>
              }
            />
          ) : (
            <div className={styles.loading}>
              <LoadingSpinnerIcon color="white" />
            </div>
          )}
        </section>
        <Footer
          center={
            <SessionControlsWrapper
              startTimer={startTimer}
              resetTimer={resetTimer}
              setShowCountdown={setShowCountdown}
            />
          }
          right={
            <div className={styles.bottomRightContainer}>
              <Tooltip
                text={
                  isHost
                    ? 'Stop recording to end session'
                    : 'You can only leave once recording is done'
                }
                position={TooltipPosition.WEST}
                disabled={!isRecording}
              >
                <EndSessionButton
                  isHost={isHost}
                  state={
                    isRecording ? ButtonState.Disabled : ButtonState.Enabled
                  }
                  onClick={openEndSessionConfirmModal}
                />
              </Tooltip>
            </div>
          }
          left={
            [NODE_ENVS.DEVELOPMENT, NODE_ENVS.STAGING].includes(
              import.meta.env.MODE
            ) &&
            isHost && (
              <div className={styles.bottomLeftContainer}>
                <SecondaryButton
                  text="Add"
                  onClick={() =>
                    setTestParticipants((c) =>
                      Math.min(c + 1, MAX_PARTICIPANTS - peers.length)
                    )
                  }
                />
                <SecondaryButton
                  text="Remove"
                  onClick={() => setTestParticipants((c) => Math.max(c - 1, 0))}
                />
                <span># Test users: {testParticipants}</span>
              </div>
            )
          }
        />
      </div>

      <aside className={wrapperClass}>
        <div
          className={backgroundClass}
          onClick={handleCloseSideBarClicked}
        ></div>
        <section className={panelClass}>
          <div className={chatWrapperClass}>
            <ParticipantChatWrapper
              isOpen={showChat}
              close={handleParticipantChatClicked}
            />
          </div>

          <div className={participantWrapperClass}>
            <ParticipantListWrapper
              open={showParticipants}
              close={handleParticipantListClicked}
            />
          </div>
        </section>
      </aside>

      <HelpModal
        open={helpModalOpen}
        onCloseClicked={() => setHelpModalOpen(false)}
      />

      <UploadingModal
        open={uploadingModalOpen}
        progressText={progressText}
        complete={isComplete}
        isHost={isHost}
        onNextClicked={handleGoToTakeClicked}
      />

      {isConnected && cameraBlobUrl && isHost && (
        <EndTakeModal
          take={take}
          open={finishTakeModalOpen}
          isGroup={isGroup}
          cameraUrl={cameraBlobUrl}
          screenUrls={screenBlobUrls}
          recordingTime={recordingTime}
          onSubmitTake={handleSubmitTake}
          onDeleteTake={handleDeleteTake}
          close={handleFinishTake}
        />
      )}

      {showCountdown && <CountDownTimer complete={handleStartRecording} />}

      <ConfirmModal
        open={endSessionConfirmModalOpen}
        title={isHost ? CONFIRM_END_SESSION_TITLE : CONFIRM_LEAVE_SESSION_TITLE}
        message={
          isHost ? CONFIRM_END_SESSION_MESSAGE : CONFIRM_LEAVE_SESSION_MESSAGE
        }
        confirm={handleLeaveSession}
        cancel={() => setEndSessionConfirmModalOpen(false)}
      />
    </section>
  );
};
