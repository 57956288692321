import { getApp, initializeApp } from 'firebase/app';
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck
} from 'firebase/app-check';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

import { firebaseConfig } from '../config/firebase';

initializeApp(firebaseConfig);

if (import.meta.env.MODE === 'development') {
  // @ts-expect-error throws error if not in development mode
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    import.meta.env.VITE_FIREBASE_APPCHECK_DEBUG_TOKEN;

  connectAuthEmulator(getAuth(), 'http://127.0.0.1:9099');
  connectDatabaseEmulator(getDatabase(), '127.0.0.1', 9000);
  connectFirestoreEmulator(getFirestore(), '127.0.0.1', 8082);
  connectFunctionsEmulator(getFunctions(), '127.0.0.1', 5001);
}

initializeAppCheck(getApp(), {
  provider: new ReCaptchaEnterpriseProvider(
    import.meta.env.VITE_RECAPTCHA_SITE_KEY
  ),
  isTokenAutoRefreshEnabled: true
});
