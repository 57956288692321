import { useCallback, useEffect, useState } from 'react';

import { getDatabase, off, onValue, ref, set } from 'firebase/database';

export default function useHostRecording(
  isHost: boolean,
  eventId?: string,
  groupId?: string
) {
  const [hostRecording, setHostRecording] = useState(false);

  const recordingGroupRef = ref(
    getDatabase(),
    `groupRecordings/${eventId}/${groupId}/recording`
  );

  useEffect(() => {
    if (!isHost && eventId && groupId) {
      onValue(recordingGroupRef, (snapshot) => {
        const data = snapshot.val() as boolean;
        setHostRecording(data);
      });

      return () => off(recordingGroupRef);
    }
  }, []);

  useEffect(() => {
    if (eventId && groupId) {
      set(recordingGroupRef, hostRecording);
    }
  }, [hostRecording]);

  const toggleHostRecording = useCallback(() => {
    setHostRecording((c) => !c);
  }, []);

  return { hostRecording, toggleHostRecording, setHostRecording };
}
