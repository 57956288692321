import { useEffect, useState } from 'react';

import { selectIsLocalScreenShared, useHMSStore } from '@100mslive/react-sdk';

import { useRecordingContext } from '../providers/RecordingProvider';

export default function useScreenMediaRecorder() {
  const isScreenShared = useHMSStore(selectIsLocalScreenShared);

  const { isRecording, uploadScreen, storeScreenChunk } = useRecordingContext();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );

  const createScreenMediaRecorder = (ref: MediaRecorder) => {
    if (!ref && mediaRecorder?.state === 'recording') {
      mediaRecorder.stop();
    }

    setMediaRecorder(ref);
  };

  const stopScreenMediaRecorder = () => {
    if (mediaRecorder?.state === 'recording') {
      mediaRecorder.stop();
    } else if (mediaRecorder?.state === 'inactive') {
      console.log(
        `Screen recording stopped [inactive]: ${new Date().toISOString()}`
      );
      setMediaRecorder(null);
    }
  };

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = (event) => {
        console.log(`Screen recording chunk: ${new Date().toISOString()}`);
        uploadScreen(event.data);
        storeScreenChunk(event.data);
        setMediaRecorder(null);
      };

      mediaRecorder.onstop = () => {
        if (!isScreenShared) {
          console.log(`Screen recording stopped: ${new Date().toISOString()}`);
          setMediaRecorder(null);
        }
      };

      mediaRecorder.onstart = () => {
        console.log(`Screen recording started: ${new Date().toISOString()}`);
      };

      mediaRecorder.onerror = () => {};
    }
  }, [mediaRecorder, isScreenShared]);

  useEffect(() => {
    if (mediaRecorder) {
      if (isScreenShared && isRecording && mediaRecorder.state === 'inactive') {
        mediaRecorder.start();
      } else if (!isRecording && mediaRecorder.state === 'recording') {
        mediaRecorder.stop();
      }
    }
  }, [isRecording, mediaRecorder, isScreenShared]);

  return { createScreenMediaRecorder, stopScreenMediaRecorder };
}
