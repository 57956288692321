export const enum Background {
  Image1 = 'image1',
  Image10 = 'image10',
  Image2 = 'image2',
  Image3 = 'image3',
  Image4 = 'image4',
  Image5 = 'image5',
  Image6 = 'image6',
  Image7 = 'image7',
  Image8 = 'image8',
  Image9 = 'image9',
  LightBlur = 'light-blur',
  None = 'none',
  NormalBlur = 'normal-blur'
}
