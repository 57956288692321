import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import {
  selectHasPeerHandRaised,
  selectIsConnectedToRoom,
  selectIsLocalAudioEnabled,
  selectIsLocalScreenShared,
  selectIsLocalVideoEnabled,
  selectLocalPeer,
  selectPeers,
  useHMSStore
} from '@100mslive/react-sdk';
import Cookies from 'universal-cookie';

import { SCREEN_SHARE_TIPS_COOKIE } from '../../config/cookies';
import { Roles } from '../../constants/roles';
import { TIME_CODE } from '../../constants/timeCode';
import { hmsActions, hmsStore } from '../../hms';
import useCreateTimeCode from '../../hooks/useCreateTimeCode';
import useHostRecording from '../../hooks/useHostRecording';
import useSomeoneScreenSharing from '../../hooks/useSomeoneScreenSharing';
import { useAppState } from '../../providers/AppState';
import { useMediaRecorderContext } from '../../providers/MediaRecorderProvider';
import { useRecordingContext } from '../../providers/RecordingProvider';
import { ScreenShareModal } from '../../stories/Modals/ScreenshareModal/ScreenShareModal';
import { SettingsModal } from '../../stories/Modals/SettingsModal/SettingsModal';
import { SessionControls } from '../../stories/SessionControls/SessionControls';

const SESSION_CONTROLS_INITIAL_STATE: SessionControls = {
  videoEnabled: true,
  audioEnabled: true,
  screenShareEnabled: false,
  isRecording: false
};

export interface SessionControlsWrapperProps {
  resetTimer: () => void;
  setShowCountdown: Dispatch<SetStateAction<boolean>>;
  startTimer: () => void;
}

export const SessionControlsWrapper = ({
  startTimer,
  resetTimer,
  setShowCountdown
}: SessionControlsWrapperProps) => {
  const prevVideoEnabledRef = useRef(true);
  const prevAudioEnabledRef = useRef(true);

  const localPeer = hmsStore.getState(selectLocalPeer);

  const { isGroup, groupId, eventId, sessionData } = useAppState();
  const {
    isRecording,
    take,
    toggleRecording,
    startScreenRecording,
    videosEnabled
  } = useRecordingContext();
  const { hasCameraMediaRecorder } = useMediaRecorderContext();
  const { addTimeCode } = useCreateTimeCode();
  const { toggleHostRecording } = useHostRecording(
    localPeer?.roleName === Roles.Host,
    eventId,
    groupId
  );
  const { someoneScreenSharing, toggleScreenSharing, setSomeoneScreenSharing } =
    useSomeoneScreenSharing(eventId, groupId);
  const peers = useHMSStore(selectPeers);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const videoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const isLocalScreenSharing = hmsStore.getState(selectIsLocalScreenShared);
  const isHandRaised = useHMSStore(
    selectHasPeerHandRaised(localPeer?.id || '')
  );

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showScreenShareModal, setShowScreenShareModal] = useState(false);

  const openSettingsModal = () => setShowSettingsModal(true);
  const closeSettingsModal = () => setShowSettingsModal(false);
  const openScreenShareModal = () => setShowScreenShareModal(true);
  const closeScreenShareModal = () => setShowScreenShareModal(false);

  const [, setControls] = useState<SessionControls>(
    SESSION_CONTROLS_INITIAL_STATE
  );

  const handleVideoClicked = async () => {
    try {
      await hmsActions.setLocalVideoEnabled(!videoEnabled);
      setControls((c) => ({ ...c, videoEnabled: !c.videoEnabled }));
    } catch (error) {
      console.error('Error with video', error);
    }
  };

  const handleAudioClicked = async () => {
    try {
      await hmsActions.setLocalAudioEnabled(!audioEnabled);
      setControls((c) => ({ ...c, audioEnabled: !c.audioEnabled }));
    } catch (error) {
      console.error('Error with audio', error);
    }
  };

  const handlePreScreenShareClicked = () => {
    if (isLocalScreenSharing) {
      handleScreenShareClicked();
    } else {
      const cookies = new Cookies(null, { path: '/' });
      const hideTips = Boolean(cookies.get(SCREEN_SHARE_TIPS_COOKIE.NAME));

      if (!hideTips) openScreenShareModal();
      else handleScreenShareClicked();
    }
  };

  const handleScreenModalShareClicked = (neverShowAgain: boolean) => {
    const cookies = new Cookies(null, { path: '/' });
    if (neverShowAgain) {
      cookies.set(
        SCREEN_SHARE_TIPS_COOKIE.NAME,
        'true',
        SCREEN_SHARE_TIPS_COOKIE.OPTIONS
      );
    }

    closeScreenShareModal();
    handleScreenShareClicked();
  };

  const handleScreenShareClicked = async () => {
    try {
      if (sessionData && localPeer) {
        await addTimeCode(
          sessionData.uploadId,
          localPeer.id,
          !isLocalScreenSharing
            ? TIME_CODE.START_SCREEN_SHARE
            : TIME_CODE.STOP_SCREEN_SHARE,
          take
        );

        toggleScreenSharing();
        await hmsActions.setScreenShareEnabled(!isLocalScreenSharing);
        if (isLocalScreenSharing) startScreenRecording();

        setControls((c) => ({
          ...c,
          screenShareEnabled: !c.screenShareEnabled
        }));
      }
    } catch (error) {
      toggleScreenSharing();
      console.error('Error with screen share', error);
    }
  };

  const handleHandRaisedClicked = async () => {
    if (isHandRaised) {
      await hmsActions.lowerLocalPeerHand();
    } else {
      await hmsActions.raiseLocalPeerHand();
    }
  };

  const handleRecordingClicked = () => {
    if (isRecording) {
      resetTimer();
    } else {
      setShowCountdown(true);
      startTimer();
    }

    toggleRecording();
    toggleHostRecording();
  };

  useEffect(() => {
    setSomeoneScreenSharing(isLocalScreenSharing);
  }, [isLocalScreenSharing]);

  useEffect(() => {
    const prevVideoEnabled = prevVideoEnabledRef.current;
    if (prevVideoEnabled !== videoEnabled && sessionData && localPeer) {
      void addTimeCode(
        sessionData.uploadId,
        localPeer.id,
        videoEnabled ? TIME_CODE.ENABLED_VIDEO : TIME_CODE.DISABLED_VIDEO,
        take
      );

      prevVideoEnabledRef.current = videoEnabled;
    }
  }, [videoEnabled]);

  useEffect(() => {
    const prevAudioEnabled = prevAudioEnabledRef.current;
    if (prevAudioEnabled !== audioEnabled && sessionData && localPeer) {
      void addTimeCode(
        sessionData.uploadId,
        localPeer.id,
        audioEnabled ? TIME_CODE.ENABLED_AUDIO : TIME_CODE.DISABLED_AUDIO,
        take
      );

      prevAudioEnabledRef.current = audioEnabled;
    }
  }, [audioEnabled]);

  const allVideosEnabled = peers
    .filter((it) =>
      [Roles.Host, Roles.Participant].includes(it.roleName as Roles)
    )
    .every((it) => videosEnabled.has(it.id));

  return (
    <>
      <SessionControls
        isGroup={isGroup}
        isHost={localPeer?.roleName === Roles.Host}
        videoEnabled={videoEnabled && hasCameraMediaRecorder}
        allParticipantsVideoEnabled={allVideosEnabled}
        audioEnabled={audioEnabled}
        isRecording={isRecording}
        isLocalSharingScreen={isLocalScreenSharing}
        someoneScreenSharing={someoneScreenSharing}
        isHandRaised={isHandRaised}
        onVideoClicked={handleVideoClicked}
        onAudioClicked={handleAudioClicked}
        onScreenShareClicked={handlePreScreenShareClicked}
        onRecordingClicked={handleRecordingClicked}
        onRaiseHandClicked={handleHandRaisedClicked}
        onSettingsClicked={openSettingsModal}
      />
      {isConnected && (
        <SettingsModal
          open={showSettingsModal}
          onCloseClicked={closeSettingsModal}
        />
      )}

      {isConnected && (
        <ScreenShareModal
          open={showScreenShareModal}
          onCloseClicked={closeScreenShareModal}
          onShareClicked={handleScreenModalShareClicked}
        />
      )}
    </>
  );
};
